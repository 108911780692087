import React from 'react';
import { List, Datagrid, TextField, EmailField, TextInput, NumberInput, Edit,
    SimpleForm } from 'react-admin';

export const ActivityTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : 'Editeaza proiectul'}</span>;
};

const updateUser = () => {

}

export const EditActivity = (props) => (
    <Edit title={<ActivityTitle />} {...props}>
        <SimpleForm>
            <TextInput source={"id"} disabled/>
            <TextInput source={"name"}/>
            <TextInput source={"description"} />
            <TextInput source={"contact"} placeholder={"Persoana de contact"} />
            <TextInput source={"date"} placeholder={"Data proiectului"} />
            <TextInput source={"time"} placeholder={"Durata (in minute)"} />
        </SimpleForm>
    </Edit>
)