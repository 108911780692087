import React, { useState, Fragment } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {
    TextInput, Edit, SimpleForm, NumberInput, useGetOne, useGetList, TopToolbar, ShowButton
} from 'react-admin';
import EditPaymentField from "./EditPaymentsField";
import { months, years } from "./globals";

export const PaymentTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : 'Editeaza campurile'}</span>;
};

export default function PaymentsEdit(props) {

    const MonthEdit = (index) => {
        return <NumberInput source={`payments[${index}]['sum']`} label={months[index].name} />
    }

    return (
        <Fragment>

            <Edit title={<PaymentTitle />} {...props}>
                <SimpleForm>
                    <TextInput source="name" disabled />
                    {MonthEdit(0)}
                    {MonthEdit(1)}
                    {MonthEdit(2)}
                    {MonthEdit(3)}
                    {MonthEdit(4)}
                    {MonthEdit(5)}
                    {MonthEdit(6)}
                    {MonthEdit(7)}
                    {MonthEdit(8)}
                    {MonthEdit(9)}
                    {MonthEdit(10)}
                    {MonthEdit(11)}
                </SimpleForm>
            </Edit>
        </Fragment>

    )
}