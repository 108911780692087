import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'ra-core';
const get = require("lodash.get");

const MonthlyPaymentField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return <p style={{ color: record.payments[source].sum !== 0 ? 'green' : 'red', margin: 0, textAlign: 'center', fontWeight: 'bold'}}>{record.payments[source].sum}</p>
}
MonthlyPaymentField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired
}

export default MonthlyPaymentField;