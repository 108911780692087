
export const months=[
    {
        id: 0,
        name: "Ianuarie"
    },
    {
        id: 1,
        name: "Februarie"
    },
    {
        id: 2,
        name: "Martie"
    },
    {
        id: 3,
        name: "Aprilie"
    },
    {
        id: 4,
        name: "Mai"
    },
    {
        id: 5,
        name: "Iunie"
    },
    {
        id: 6,
        name: "Iulie"
    },
    {
        id: 7,
        name: "August"
    },
    {
        id: 8,
        name: "Septembrie"
    },
    {
        id: 9,
        name: "Octombrie"
    },
    {
        id: 10,
        name: "Noiembrie"
    },
    {
        id: 11,
        name: "Decembrie"
    },
]

export const years = [
    {
        id: "2021",
        name: "2021",
    },
    {
        id: "2022",
        name: "2022",
    },
    {
        id: "2023",
        name: "2023",
    },
    {
        id: "2024",
        name: "2024",
    },
    {
        id: "2025",
        name: "2025",
    },
]