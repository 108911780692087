import React, {useState, Fragment, useEffect} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    TextInput, Edit, SimpleForm, NumberInput, useGetOne, useGetList, TopToolbar, ShowButton 
} from 'react-admin';
import ActivityParticipants from './ActivityParticipants';
import QRCode from 'qrcode.react';
import ParticipantsPdf from './participantsPdf';
import ReactPDF, { PDFViewer } from '@react-pdf/renderer';
import Report from './templateRaportLunar';
import generatePDF from './fisaRegistru';
import Button from '@material-ui/core/Button';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

const ActivityTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : ''}</span>;
};

const ActivityActions = (pdfData, projectData) => {
    return (
        <TopToolbar>
            <Button color="primary" onClick={() => generatePDF(pdfData, projectData)}>Descarcă fișa de registru</Button>
        </TopToolbar>
    )
}

const ActivityEditor = props => {
    return (
        <Edit title={<ActivityTitle />} {...props}>
            <SimpleForm>
                <TextInput source={"id"} disabled/>
                <TextInput source={"name"}/>
                <TextInput source={"description"} />
                <TextInput source={"contact"} placeholder={"Persoana de contact"} />
                <TextInput source={"date"} placeholder={"Data proiectului"} />
                <TextInput source={"organizer"} placeholder={"Coordonatorul proiectului"} />
                <NumberInput source={"time"} placeholder={"Durata (in minute)"} />
            </SimpleForm>
        </Edit>
    )
}

export default function ActivityEdit(props) {

    const [value, setValue] = useState(0);
    const [pdfData, setPdf] = useState([]);
    const [activities, setActivities] = useState([]);
    const [loadedData, setLoaded] = useState(false);
    const projectData = useGetOne("projects", props.match.params.id);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    useEffect(() => {
        getPdfData(props.match.params.id);
    }, []);

    const getPdfData = async (id) => {
        await fetch(`https://api.davinci.fun/appmos-server/participants/${id}`)
            .then(response => response.json())
            .then((json) => {
                setPdf(json);
                setLoaded(true);
            })
            .catch(error => {
                console.error(error);
            })
    }

    return (

        <Fragment>
            
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Detalii activitate" value={0} id={"detalii"} />
                <Tab label="Participanţi" value={1} id={"participants"} />
                <Tab label="Cod QR" value={2} id={"qr"}/>
                {/* <Tab label="Fișă de prezență" value={3} id="prezenta"/> */}
            </Tabs>

            <TabPanel value={value} index={0}>
                {ActivityActions(pdfData, projectData)}
                {ActivityEditor(props)}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {ActivityActions(pdfData, projectData)}
                <ActivityParticipants id={props.match.params.id} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                {ActivityActions(pdfData, projectData)}
                <QRCode style={{ margin: '3%'}} value={`https://moseadori.amosed.ro/${props.id}`} size={300} />
            </TabPanel>
        </Fragment>
    )

}