import * as React from "react";
import {
    List, Datagrid, TextField, TextInput, NumberInput, Edit,
    SimpleForm, Button, TopToolbar, BooleanInput, ReferenceInput, SelectInput,
    useRecordContext
} from 'react-admin';
import IconEvent from '@material-ui/icons/Event';
import PropTypes  from "prop-types";
import MonthlyPaymentField from "./MonthlyPaymentsField";
import { months, years } from "./globals";

export const paymentFilters = [
    <TextInput label="Cauta" source="name" alwaysOn />,
    <SelectInput source="luna"  
        choices={months}
        alwaysOn
    />,
    <SelectInput source="Anul"  
        choices={years}
    />,
];

const Months = (index) => {
    return <MonthlyPaymentField source={index} label={months[index].name.toString()} />
}

export const PaymentsList = props => {

    return (
    <List filters={paymentFilters} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" label={"Nume"} />
            {Months(0)}
            {Months(1)}
            {Months(2)}
            {Months(3)}
            {Months(4)}
            {Months(5)}
            {Months(6)}
            {Months(7)}
            {Months(8)}
            {Months(9)}
            {Months(10)}
            {Months(11)}
        </Datagrid>
    </List>
)};