import { fetchUtils } from 'react-admin';
// const apiUrl = 'http://localhost:8081/admin';
const apiUrl = 'https://api.davinci.fun/appmos-server/admin'
const httpClient = fetchUtils.fetchJson;
export default {
    // called when the user attempts to log in
    login: async ({ username, password }) => {

        const user = await fetch(`${apiUrl}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({username, password}),
        });
        console.log(user);
        if (user.status === 200) {
            localStorage.setItem('username', username);
            return Promise.resolve();
        }
        else {
            return Promise.reject();
        }
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('username');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('username')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};